import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { Button } from 'stile-shared/src/components/2020/common/Button';
import { Modal, useModal } from 'stile-shared/src/components/2020/common/Modal';
import { Heading, Text } from 'stile-shared/src/components/2020/common/Primitives';
import { StileXSampleButton } from 'stile-shared/src/components/2020/common/StileXSampleButton';
import { StileXSampleModal } from 'stile-shared/src/components/2020/common/StileXSampleModal';
import { Box } from 'stile-shared/src/components/2020/layout/Box';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Grid } from 'stile-shared/src/components/2020/layout/Grid';
import { theme } from 'stile-shared/src/styles/2020/theme';

import { LabKitFormUs } from './LabKitFormUs';

type StileXSampleButtonUsProps = Omit<
  React.ComponentProps<typeof StileXSampleButton>,
  'form' | 'content'
>;

export function LabKitButton(props: StileXSampleButtonUsProps) {
  return (
    <BaseButton
      {...props}
      //   gtagEvent="stile_x_sample_button_us"
      form={<LabKitFormUs />}
      content={
        <Column space={theme.space.mToL}>
          <Column>
            <Text as="h3" size="l" semiBold>
              Stile Lab Kits in your district
            </Text>
            <Text>
              Bring your science classroom to the next level with Stile Lab Kits, where
              environmental responsibility meets effortless teaching.
            </Text>
            <Text>
              Our kits transform complex scientific concepts into accessible, hands-on experiences
              while ensuring consistent and dependable performance for true peace of mind in your
              classroom.
            </Text>
            <Text>
              Stile Lab Kits are available for our core middle school curricular units. We look
              forward to helping you make science 1000% more hands-on with Stile Lab Kits!
            </Text>
          </Column>

          <StaticImage src="../../../../stile-shared/assets/images/lab-kits/kit_form.png" alt="" />
        </Column>
      }
    />
  );
}

type BaseButtonProps = React.ComponentProps<typeof Button> &
  Pick<React.ComponentProps<typeof StileXSampleModal>, 'form' | 'content'>;

export function BaseButton({ form, content, ...props }: BaseButtonProps) {
  const { openModal, closeModal, isModalOpen } = useModal();

  return (
    <>
      <Button variant="cta" {...props} onClick={openModal}>
        Request lab kit information now →
      </Button>

      <BaseModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        label="Request more information on lab kits"
        form={form}
        content={content}
      />
    </>
  );
}

type BaseModalProps = React.ComponentProps<typeof Modal> & {
  form: React.ReactNode;
  content: React.ReactNode;
};

export function BaseModal({ form, content, ...props }: BaseModalProps) {
  return (
    <Modal {...props}>
      <Box space={theme.space.mToL} usePadding>
        <Grid min="450px" space={theme.space.xl}>
          <Column>
            <Heading as="h2">Request more information on lab kits</Heading>
            {form}
          </Column>

          {content}
        </Grid>
      </Box>
    </Modal>
  );
}
