import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { Card } from '../../common/Card';
import { H2, P } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { FullWidthBackground } from '../../layout/FullWidthBackground';
import { Switcher } from '../../layout/Switcher';

type IllustrationCardBlockVariant = 'inherit' | 'dark' | 'beige';

type IllustrationCardsBlockProps = React.ComponentProps<typeof Switcher> & {
  title?: string;
  variant?: IllustrationCardBlockVariant;
  cards: React.ComponentProps<typeof Card>[];
  text?: string;
  space?: React.CSSProperties['marginTop'];
};

export function IllustrationCardsBlock({
  title,
  variant = 'inherit',
  text,
  cards,
  space = theme.space.l,
  children,
  ...props
}: IllustrationCardsBlockProps) {
  return (
    <FullWidthBackground
      forwardedAs="section"
      invertColors={variant === 'dark'}
      secondaryColor={variant === 'beige' ? theme.colors.beige : theme.colors.white}
      intrinsic={false}
    >
      <Column space={theme.space.l}>
        <H2>{title}</H2>
        {text ? <P>{text}</P> : children}
        <Switcher space={space} limit={3} threshold="600px" {...props}>
          {cards.map((card) => (
            <Card key={card.title} {...card} />
          ))}
        </Switcher>
      </Column>
    </FullWidthBackground>
  );
}
