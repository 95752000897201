import * as React from 'react';

import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Button } from 'stile-shared/src/components/2020/common/Button';
import {
  Form,
  FormField,
  FormFieldError,
  Input,
} from 'stile-shared/src/components/2020/common/Form';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { Text } from 'stile-shared/src/components/2020/common/Primitives';
import { Select } from 'stile-shared/src/components/2020/common/Select';
import type { StileApiInboundLeads, StileInboundLeadUs } from 'stile-shared/src/types/services';
import { tagEvent } from 'stile-shared/src/utils/tagEvent';
import { emailValidator, phoneValidator } from 'stile-shared/src/utils/validators';

type FormData = Omit<StileInboundLeadUs['usContact'], 'referrer' | 'leadType'>;

export function LabKitFormUs() {
  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormData>();

  const {
    mutate,
    isLoading,
    reset: resetMutation,
    isSuccess,
    isError,
  } = useMutation(createEnquiry, {
    onSuccess: () => {
      tagEvent('stile_x_sample_form_submit_us');
      resetForm();
    },
    retry: 5,
  });

  const onSubmit = (formData: FormData) => {
    const submissionData: StileInboundLeadUs = {
      usContact: {
        ...formData,
        leadType: 'LAB_KIT',
      },
    };
    mutate(submissionData);
  };

  return isSuccess ? (
    <Text>Thanks! One of our team members will be in touch shortly.</Text>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormField>
        <Input
          {...register('firstName', {
            maxLength: { value: 100, message: '100 character limit' },
            required: 'This field is required',
          })}
          label="First name*"
          placeholder="Melissa"
          aria-invalid={errors.firstName ? 'true' : 'false'}
        />
        <FormFieldError name="firstName" errors={errors} />
      </FormField>

      <FormField>
        <Input
          label="Last name*"
          placeholder="Rodriguez"
          aria-invalid={errors.lastName ? 'true' : 'false'}
          {...register('lastName', {
            maxLength: { value: 100, message: '100 character limit' },
            required: 'This field is required',
          })}
        />
        <FormFieldError name="lastName" errors={errors} />
      </FormField>

      <FormField>
        <Input
          label="District*"
          placeholder="Los Angeles Unified School District"
          {...register('district', {
            maxLength: { value: 200, message: '200 character limit' },
            required: 'This field is required',
          })}
        />
      </FormField>

      <FormField>
        <Input
          type="text"
          label="Zip code*"
          placeholder="90001"
          {...register('zipCode', {
            maxLength: { value: 20, message: '20 character limit' },
            required: 'This field is required',
            pattern: {
              value: /\d+/,
              message: 'Zip code invalid',
            },
          })}
        />
      </FormField>

      <FormField>
        <Input
          type="email"
          label="Email*"
          aria-invalid={errors.email ? 'true' : 'false'}
          placeholder="mrodriguez@usd.k12.gov"
          {...register('email', {
            maxLength: { value: 200, message: '200 character limit' },
            required: 'This field is required',
            pattern: {
              value: emailValidator,
              message: 'Email address invalid',
            },
          })}
        />
        <FormFieldError name="email" errors={errors} />
      </FormField>

      <FormField>
        <Input
          type="tel"
          label="Phone*"
          placeholder="213-555-2525"
          aria-invalid={errors.phone ? 'true' : 'false'}
          {...register('phone', {
            required: 'This field is required',
            pattern: {
              value: phoneValidator,
              message: 'Phone number invalid',
            },
          })}
        />
        <FormFieldError name="phone" errors={errors} />
      </FormField>

      <FormField>
        <label htmlFor="stile-user">Do you already use Stile?</label>
        <Select
          id="stile-user"
          options={[
            { value: '', text: '[Select]' },
            { value: 'true', text: 'Yes' },
            { value: 'false', text: 'No' },
          ]}
          {...register('currentUser', { required: 'This field is required' })}
        />
      </FormField>

      <FormField>
        <label htmlFor="grade-level">
          Which grade level are you interested in exploring kits for?
        </label>
        <Select
          id="grade-level"
          options={[
            { value: '', text: '[Select]' },
            { value: '6th', text: '6th' },
            { value: '7th', text: '7th' },
            { value: '8th', text: '8th' },
          ]}
          {...register('gradeLevel', { required: 'This field is required' })}
        />
      </FormField>

      <FormField>
        <Input
          label="Anything else you would like us to know? "
          {...register('additionalQueries', {
            maxLength: { value: 300, message: '300 character limit' },
          })}
        />
      </FormField>

      {isError && (
        <Text color="error">
          Something went wrong submitting your details, please try again and if the issue persists{' '}
          <Link variant="inherit" url="/who-we-are/contact/">
            get in touch with us
          </Link>
          .
        </Text>
      )}

      <Button type="submit" onClick={resetMutation} disabled={isLoading}>
        {isLoading ? 'Submitting...' : 'Submit'}
      </Button>
    </Form>
  );
}

const createEnquiry = async (data: StileApiInboundLeads) => {
  const config = {
    headers: { 'Content-Type': 'application/json' },
  };

  const { data: response } = await axios.post(
    'https://stileapp.com/api/au/v3/inboundLeads',
    data,
    config
  );

  return response.data;
};
